import {createRouter, createWebHashHistory} from 'vue-router'
import main from "@/pager/main/main";
//import home from "@/pager/home/main";

const router= createRouter({
  history: createWebHashHistory(),
  routes: [
   /* {path: '/', redirect: '/'},*/
    {path: '/', name: 'Main',  component: main}
  ]
})
export default router

