<template>
  <div class="main">
    <h1 style="color: black;text-align: center;font-size: 30px">易飞行</h1>
    <h2 style="color: black;text-align: center;font-size: 30px">让飞行更简单</h2>
    <p style="color: black;text-align: center;font-size: 30px">已支持的机型</p>
    <p style="color: black;text-align: center;font-size: 18px">
      DJI_MINI_2, DJI_AIR_2S, MAVIC_AIR_2, MAVIC_AIR, DJI_MINI_SE, MAVIC_PRO, MAVIC_2_PRO</p>
    <p style="color:red;text-align: center;font-size:18px">想要其他机型请入群:722035510</p>
    <h2 style="font-size: 20px;text-align: center;color: #333333;margin-top: 30px">主要功能</h2>
    <p style="font-size: 15px;text-align: center;color: #333333;margin-top: 10px">航线飞行,仿地飞行,航点飞行</p>
    <p style="font-size: 15px;text-align: center;color: #333333;margin-top: 10px">全景,跟随,兴趣点飞行</p>
    <h2 style="font-size: 20px;text-align: center;color: #333333;margin-top: 30px">目前已开发功能</h2>
    <p style="font-size: 15px;text-align: center;color: #333333;margin-top: 10px">航线飞行,仿地飞行,全景（PT GUI合成，后期app会支持自动合成）</p>
    <p style="color:red;text-align: center;font-size:18px">下载请用浏览器扫描下方二维码</p>
    <span style="width: 100% ;display: flex;justify-content: center">
    <img style="width: 180px;height: 180px" src="../../assets/load.png"/>
    </span>
    <p style="color:red;text-align: center;font-size:18px">请点击：<a  href="https://www.bilibili.com/video/BV1X24y1y7xM/?share_source=copy_web&vd_source=f716aa7c543ad045d7dcc8c05533acbd">使用教程</a></p>
    <p style="color:black;text-align: center;font-size:18px">如果打不开,请到哔哩哔哩搜索：易飞行</p>
    <p style="color:black;text-align: center;font-size:18px"><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">备案号: 豫ICP备2022021272号-1</a></p>

  </div>
</template>

<script src="./_main.js" lang="js"></script>
<style src="./_main.css" scoped></style>
